
































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class TramitesMunicipales extends Vue {
  private servicios: Array<any> = [];
  private base = process.env.VUE_APP_BASE_URL;

  private mounted() {
    this.getServicios();
  }

  private getServicios() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("/servicios/general?tipo=tramites")
      .then((res: any) => {
        const cantidadElementos = res.data.length;
        for (let i = 0; i < cantidadElementos; i++) {
          const numero = (i % 5) + 1;
          res.data[i].numero = numero;
          // Verificar y modificar el formato del enlace
          // if (res.data[i].link && !/^https:\/\/www\./.test(res.data[i].link)) {
          //   res.data[i].link = "https://" + res.data[i].link;
          // }
        }
        this.servicios = res.data;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private toLink(item: any, blank: any) {
    if (item && item != "") {
      if (blank == 1) {
        window.open(item, "_blank");
      } else {
        window.open(item, "_self");
      }
    } else {
      return;
    }
  }
}
